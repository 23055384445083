import React, { useContext, useEffect, useState, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/pro-regular-svg-icons'
import { Row, Col, Container } from '@kogk/common'
import { GlobalDataProvider } from '@src/data/global-data'
import {
  Button,
  MainLayout,
  UserPrescriptions,
  RadioButton,
  UserInsuranceStep
} from '@cmp/site'
import cn from 'classnames'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import styles from './LyfjagattPage.module.scss'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'
import { Fade } from 'react-reveal'
import { BlueContentSection } from '@src/components/sections'
import { Link } from '@kogk/gatsby-theme-base'
import StadfestaImg from '@src/assets/images/stadfesta-logmaeti.png'
import {
  redirect,
  LOGOUT_PATH
} from '@root/src/utils/index'
import { useAuthentication } from '@root/src/context/Authentication'
import {
  GET_VIEWER_DETAILS,
  GET_PRESCRIPTIONS,
  GET_VIEWER_INSURANCE
} from '../../../data/backend/queries'

export const query = graphql`
  query {
    allPrismicAlertbanner {
      nodes {
        ...fragmentPrismicAlertbanner
      }
    }
  }
`

const useLyfjagatt = () => {
  const { t } = useLanguage()
  const auth = useAuthentication()
  const [selectedUser, setSelectedUser] = useState()

  const detailsQuery = useQuery(GET_VIEWER_DETAILS)

  const insuranceQuery = useQuery(GET_VIEWER_INSURANCE, {
    skip: auth.loading || !auth.user.isOfMinimumAge,
    variables: { userId: selectedUser?.id ?? auth.user?.id }
  })

  const prescriptionsQuery = useQuery(GET_PRESCRIPTIONS, {
    skip: auth.loading || !auth.user.isOfMinimumAge,
    variables: { userId: selectedUser?.id ?? auth.user?.id }
  })

  const viewer = detailsQuery.data?.viewer
  const viewerinsuranceStatus = insuranceQuery.data?.insuranceStatus

  const users = useMemo(() => {
    return viewer ? [viewer.details].concat(viewer.children) : []
  }, [viewer])

  return {
    insuranceStatus: viewerinsuranceStatus,
    error: detailsQuery.error || prescriptionsQuery.error,
    loading: auth.loading || detailsQuery.loading || prescriptionsQuery.loading,
    users,
    selectedUser: selectedUser || viewer?.details,
    onChangeUser: setSelectedUser,
    prescriptions: prescriptionsQuery.data?.prescriptions ?? [],
    unavailableMessage:
      auth.user && !auth.user.isOfMinimumAge && t('lyfjagatt|too-young')
  }
}

const logout = () => {
  const { protocol, host } = window.location
  redirect(LOGOUT_PATH, [['backTo', `${protocol}//${host}`]])
}

const LyfjagattPage = () => {
  const {
    error,
    loading,
    users,
    selectedUser,
    onChangeUser,
    prescriptions,
    unavailableMessage,
    insuranceStatus
  } = useLyfjagatt()
  const [open, setIsOpen] = useState(false)
  const alertData = useStaticQuery(query)

  const {
    visibility: showAlert,
    text: { html: text }
  } = alertData.allPrismicAlertbanner.nodes[0].data

  const profileSwitchClasses = cn(
    'pl-0 pl-md-2 pr-0 pr-md-1 mr-1',
    styles.profileSwitch,
    styles.btn
  )

  const { modify } = useContext(MenuTypeContext)
  useEffect(() => {
    modify('primary', 'Lyfjagáttin')
  }, [])

  const dropdownClasses = cn(styles.userDropdown, 'dropdown', {
    'dropdown--open': open
  })

  return (
    <MainLayout>
      {showAlert && <BlueContentSection text={text} />}
      <Container className='pt-md-3 mb-4 pb-2'>
        <Row className='justify-content-between pt-2 flex-column-reverse flex-md-row'>
          <Col className='col-12 col-md'>
            <h2 className={styles.colHeading}>Lyfjagáttin þín</h2>
          </Col>
          <Col className='d-flex col-12 col-md justify-content-end'>
            {users.length > 1 ? (
              <>
                <Button
                  label={selectedUser?.name}
                  icon='chevronDown'
                  onClick={() => setIsOpen(!open)}
                  type='mini-ghost'
                  className={profileSwitchClasses}
                />
                <div className='position-relative'>
                  <Fade reverse when={open} duration={100} top distance='15px'>
                    <div className={dropdownClasses}>
                      {users.map((user, key) => {
                        return (
                          <RadioButton
                            key={key}
                            className='radio-button__user-dropdown'
                            label={user.name}
                            onClick={() => {
                              onChangeUser(user)
                              setIsOpen(false)
                            }}
                            checked={user.id === selectedUser.id}
                          />
                        )
                      })}
                    </div>
                  </Fade>
                </div>
              </>
            ) : (
              <Button
                label={selectedUser?.name}
                type='mini-ghost'
                className={cn(profileSwitchClasses, 'pr-md-2')}
              />
            )}
            <button
              onClick={logout}
              className={cn(
                styles.signOutBtn,
                'd-none d-md-flex ml-1 the-button the-button__mini-ghost'
              )}
            >
              <FontAwesomeIcon icon={faSignOut} className='mr-1' />
              <span>Útskrá</span>
            </button>
          </Col>
        </Row>
        <Row>
          <Col col={{ md: 7 }}>
            {unavailableMessage ? (
              <div className='parag parag--small parag--markdown'>
                <ReactMarkdown skipHtml>{unavailableMessage}</ReactMarkdown>
              </div>
            ) : (
              <UserPrescriptions
                user={selectedUser}
                loading={loading}
                error={error}
                items={prescriptions}
              />
            )}
          </Col>
          <Col col={{ md: 5 }} className='pt-2 pt-lg-0'>
            <UserInsuranceStep
              insuranceStatus={insuranceStatus}
              loading={loading}
              hasData={!!insuranceStatus}
              />
          </Col>
        </Row>
        <Row className='justify-content-center d-flex d-md-none'>
          <Col>
            <button
              onClick={logout}
              className={cn(
                'the-button the-button__mini-ghost mt-3',
                styles.signOutBtn
              )}
            >
              <FontAwesomeIcon icon={faSignOut} className='mr-1' />
              <span>Skrá út</span>
            </button>
          </Col>
        </Row>
        {!unavailableMessage && (
          <Link
            to='https://www.lyfjastofnun.is/leyfisskyld-starfsemi/apotek/listi-yfir-netapotek/'
            className='d-inline-block my-2'
          >
            <img src={StadfestaImg} className={styles.legitImg} />
          </Link>
        )}
      </Container>
    </MainLayout>
  )
}

export default props => {
  const { language } = useLanguage()

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <LyfjagattPage {...props} />
    </GlobalDataProvider>
  )
}
